import React from "react";
import { HelpPopover } from "../../HelpPopover";

export function NameHelp() {
  return (
    <HelpPopover>
      Pick an Evaluation Name. Choose a name that is short but descriptive.
    </HelpPopover>
  );
}
